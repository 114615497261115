<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card title="Edit Break Hours">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">


                        <b-row>
                          <b-col md="12">
                            <b-form-group
                              label="Set Name"
                              class="required"
                            >
                              <b-form-input
                                placeholder="2 to 50 characters"
                                v-model="form.set_name"
                              />
                            </b-form-group>
                          </b-col>

                          
                        </b-row>

                        <b-row>
                            <b-col md="12">
                                <b-row>
                                    <b-col md="10">Break Hour<span class="text-danger">*</span></b-col>
                                    <b-col md="2"></b-col>
                                </b-row>

                                <b-row v-for="(fm, index) in form.breaks" :key="index" class="mt-1">
                                    <b-col md="11">
                                        <b-row>
                                            <b-col md="12">
                                                <b-form-input placeholder="3 to 50 characters" v-model="fm.duration" />
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col md="1" class="text-center">
                                        <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                    </b-col>
                                </b-row>

                                <b-row class="mt-1">

                                    <b-col md="11" class="text-center">
                                        <hr>
                                        <b-button class="mt-1" variant="primary" @click="addRow">Add More</b-button>
                                    </b-col>
                                </b-row>
                                            
                                <b-row>
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'break-hours' })">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {
        BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';

    export default {
        components: {
            BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,
                form: {
                    id:'',
                    set_name : '',
                    breaks: [{
                        duration: ''
                    }],
                },
                sites:[]
            }
        },

        methods : {
            formSubmit(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                    },
                    api : '/api/update-break-hour'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;

                        this.successAlert().then((result) => {
                            // this.$router.go(-1);
                            this.$router.push({ name:'break-hours' })
                        });
                    }
                });
            },

            addRow(){
                this.form.breaks.push({
                    duration : '',
                })
            },

            removeRow(index) {
                if(this.form.breaks.length > 1){
                    this.form.breaks.splice(index, 1);
                }
            },
            getDetail(){
                return this.$store.dispatch(POST_API, {
                    data: {
                        id : this.$route.params.id
                    },
                    api: '/api/break-hour-detail'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data = this.$store.getters.getResults.data;
                        
                        this.form.set_name      = data.set_name;
                        this.form.breaks        = data.break;
                        this.form.id            = data._id;

                        if (this.form.breaks.length == 0) {
                            this.form.breaks.push({
                                duration : '',
                            })
                        }

                    }
                });
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:null,
                text: 'Schedule Work'
              },{
                to:{name:'task-groups'},
                text: 'Group Setting',
                
              },{
                to:{name:'break-hours'},
                text: 'Manage Break Hours',
              },{
                to:null,
                text: 'Edit Break Hours',
              }];
              return item;
            }
            /*allSites(){
              return this.$store.dispatch(POST_API, {
                   data:{
                     role:this.$store.getters.currentUser.role,
                   },
                   api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sites = data;
                        return this.sites;
                    }
                });
            },*/
        },
        mounted(){
            this.getDetail();
            //this.allSites();
        }
    }
</script>
